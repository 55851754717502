import { createSelector } from 'reselect';
import { IGamesState, IJobsState } from '../slices/types';

export const jobsStateSelector = (state: any): IJobsState => state.Jobs;

export const allJobsDataSelector = createSelector(jobsStateSelector, (jobs) => ({
    data: jobs.jobs.data,
    isInProgress: jobs.isInProgress.fetchAllJobs,
    totalPages: jobs.jobs.pagination.totalPages,
    isJobInProgress: jobs.isInProgress.reRunJob,
    isDeleteInProgress: jobs.isInProgress.deleteJob,
    success: jobs.success,
    message: jobs.message,
}));
