import { BaseApiService } from './BaseApiService';
import * as url from '../../helpers/url_helper';
import {
    IReportGamesOnOperatorResponse,
    IReportPortfolioResponse,
    IReportPositionUpdatesResponse,
} from './IReportApiService';

export class ReportApiService {
    static FETCH_REPORT_GAMES_ON_OPERATOR_URL = '/reports/games-on-operator';
    static FETCH_REPORT_PORTFOLIO_URL = '/reports/portfolio';
    static FETCH_REPORT_GAMES_ON_SECTIONS_URL = '/reports/games-on-sections';
    static FETCH_REPORT_POSITION_UPDATES_URL = '/reports/position-updates';

    static getGamesOnOperator(providerId: number, gameId: number, operatorId: number) {
        return BaseApiService.get<IReportGamesOnOperatorResponse>(ReportApiService.FETCH_REPORT_GAMES_ON_OPERATOR_URL, {
            operator_id: operatorId,
            game_id: gameId,
            provider_id: providerId,
        });
    }

    static getPortfolio(providerId: number, gameId: number, operatorId: number) {
        return BaseApiService.get<IReportPortfolioResponse>(ReportApiService.FETCH_REPORT_PORTFOLIO_URL, {
            provider_id: providerId,
            game_id: gameId,
            operator_id: operatorId,
        });
    }

    static getGamesOnSections(gameId: number, operatorId: number) {
        return BaseApiService.get<IReportPortfolioResponse>(ReportApiService.FETCH_REPORT_GAMES_ON_SECTIONS_URL, {
            game_id: gameId,
            operator_id: operatorId,
        });
    }

    static getPositionUpdates(
        page: number,
        pageSize: number,
        providerId: number,
        gameIds: number[],
        operatorIds: number[],
        date: string
    ) {
        return BaseApiService.get<IReportPositionUpdatesResponse>(ReportApiService.FETCH_REPORT_POSITION_UPDATES_URL, {
            page,
            page_size: pageSize,
            provider_id: providerId,
            date,
            ...(gameIds.length > 0 ? { 'game_ids': gameIds } : {}),
            ...(operatorIds.length > 0 ? { 'operator_ids': operatorIds } : {}),
        });
    }
}
