import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IReportGame } from '../../../Services/Api/IReportApiService';
import { Search } from '../../../Components/Common/Search';
import React, { useState } from 'react';

export const AllGamesDialog = ({
    isOpen,
    onClose,
    games,
    title,
    itemKey,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    games?: IReportGame[];
    title: string;
    itemKey?: string | number;
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    onClose();
                }}
            >
                {title}
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Search search={setSearchTerm} placeholder="Search By Game Name..." />
                </div>
                {games
                    ?.filter(({ name }) => {
                        return !searchTerm || name.toLowerCase().search(searchTerm.toLowerCase()) > -1;
                    })
                    .map((game) => {
                        return (
                            <div key={`${itemKey}-all-game-dialog-${game.id}`} className="game-item mb-1 float-start">
                                {game.name}
                                {game.position && (
                                    <>
                                        {' '}
                                        <Badge color="success" key={`${itemKey}-dialog-game-info-${game.id}`}>
                                            {game.position}
                                        </Badge>
                                    </>
                                )}
                            </div>
                        );
                    })}
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};
