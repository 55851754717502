import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllJobs, reRunJob, deleteJob } from '../../slices/jobs/thunk';
import { Pagination } from 'Components/Common/Pagination';
import { IFetchAllJobsApiRequestBody } from '../../slices/types';
import { toast, ToastContainer } from 'react-toastify';
import { allJobsDataSelector } from '../../selectors/jobsSelectors';
import { SelectOperator } from '../../Components/Selects/SelectOperator';
import { formattedDate } from '../../utils/dates';
import { JobStatuses } from '../../Services/Api/Job/IJobApiService';
import { SelectJobStatus } from '../../Components/Selects/SelectJobStatus';
import { get } from 'lodash';
import flags from '../../common/flags';
import { VisibleInOptions } from '../../Services/Api/IOperatorApiService';
import { SelectVisibleIn } from '../../Components/Selects/SelectVisibleIn';
import { resetJobsReducerStatus } from '../../slices/jobs/reducer';
/* eslint-disable no-restricted-globals */
export const AllJobs = () => {
    const dispatch: any = useDispatch();

    const { data, isInProgress, isJobInProgress, isDeleteInProgress, message, success, totalPages } =
        useSelector(allJobsDataSelector);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [jobId, setJobId] = useState<number>(0);
    const [params, setParams] = useState<IFetchAllJobsApiRequestBody>({
        page: 1,
        page_size: 25,
        operator_id: 0,
        status: JobStatuses.ALL,
        original_status: JobStatuses.ALL,
        visible_in: VisibleInOptions.ALL,
        message: '',
    });

    const handleSelect = (operatorId: any) => {
        setSelectedPage(1);
        setParams({ ...params, page: 1, operator_id: operatorId });
    };
    const changePage = (page: number) => {
        setSelectedPage(page);
        setParams({ ...params, page: page });
    };
    const changeStatus = (status: JobStatuses): void => {
        setParams({ ...params, page: 1, status });
    };
    const changeOriginalStatus = (status: JobStatuses): void => {
        setParams({ ...params, page: 1, original_status: status });
    };
    const changeVisibleIn = (visible_in: VisibleInOptions): void => {
        setParams({ ...params, page: 1, visible_in });
    };

    const reRun = (original_id: number): void => {
        setJobId(original_id);
        dispatch(reRunJob({ original_id }));
    };
    const onDelete = (original_id: number): void => {
        dispatch(deleteJob(original_id));
    };

    useEffect(() => {
        dispatch(fetchAllJobs(params));
    }, [params]);

    useEffect(() => {
        if (isJobInProgress && jobId) {
            dispatch(fetchAllJobs(params));
            setJobId(0);
        }
    }, [isJobInProgress]);

    useEffect(() => {
        if (!isDeleteInProgress && success !== null) {
            dispatch(resetJobsReducerStatus());
            dispatch(fetchAllJobs(params));

            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            }
        }
    }, [isDeleteInProgress]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb title={'All Jobs'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={6} sm={3}>
                                                        <SelectVisibleIn setValue={changeVisibleIn} />
                                                    </Col>
                                                    <Col xs={6} sm={3}>
                                                        <SelectOperator
                                                            setValue={handleSelect}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operator..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={3}>
                                                        <SelectJobStatus
                                                            setValue={changeStatus}
                                                            placeholder="Filter By Parsing Status..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={3}>
                                                        <SelectJobStatus
                                                            setValue={changeOriginalStatus}
                                                            placeholder="Filter By Scrapping Status..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        {isJobInProgress && (
                                            <Alert color="danger" className="border-2 bg-body-secondary">
                                                You rerun job with ID <strong>{jobId}</strong>. Please don't refresh the
                                                page, otherwise the process will be stopped.
                                            </Alert>
                                        )}
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardHeader className="display-flex align-items-center justify-content-between">
                                                <h4 className="card-title mb-0">Jobs</h4>
                                                <Button size="sm" onClick={() => dispatch(fetchAllJobs(params))}>
                                                    Refresh
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col" className="min-w">
                                                                        Original ID
                                                                    </th>
                                                                    <th scope="col">Operator</th>
                                                                    <th scope="col">Parsing ● Scrapping Status</th>
                                                                    <th scope="col">Error Message</th>
                                                                    <th scope="col">Games Parsed</th>
                                                                    <th scope="col">Json</th>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((job: any) => (
                                                                    <tr key={`job-${job.original_id}`}>
                                                                        <td>{job.original_id}</td>
                                                                        <td>
                                                                            <img
                                                                                src={get(
                                                                                    flags,
                                                                                    `${job.operator.visible_in}.flag`
                                                                                )}
                                                                                alt="Skote"
                                                                                className="me-2 rounded"
                                                                                height="18"
                                                                            />
                                                                            {job.operator.name}
                                                                        </td>
                                                                        <td>
                                                                            {job.status === 'in_progress' ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}{' '}
                                                                            ●{' '}
                                                                            {job.original_status === 'in_progress' ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.original_status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td className="multiline-td">{job.message}</td>
                                                                        <td>
                                                                            <Badge color="success">
                                                                                {job.games_parsed}
                                                                            </Badge>
                                                                        </td>
                                                                        <td>
                                                                            <a href={job.info_url} target="_blank">
                                                                                View json
                                                                            </a>
                                                                        </td>
                                                                        <td>{formattedDate(job.created_at)}</td>
                                                                        <td className="text-end">
                                                                            {!isJobInProgress && (
                                                                                <Button
                                                                                    color={
                                                                                        job.status !== 'in_progress'
                                                                                            ? 'warning'
                                                                                            : 'success'
                                                                                    }
                                                                                    size="sm"
                                                                                    onClick={() =>
                                                                                        reRun(job.original_id)
                                                                                    }
                                                                                >
                                                                                    Re-run
                                                                                </Button>
                                                                            )}
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        //eslint-disable-line
                                                                                        confirm(
                                                                                            'Are you sure you want to delete this job'
                                                                                        )
                                                                                    ) {
                                                                                        onDelete(job.original_id);
                                                                                    }
                                                                                }}
                                                                                className="ms-2"
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={changePage} />
                </Container>
                <ToastContainer />
            </div>
        </>
    );
};
