import { fetchSelectedOperatorsApi } from './backend_helper'

export const loadSelectedOperatorSuggestions = (limit = false) => (q: string, callback: (response: any) => void) => {
    fetchSelectedOperatorsApi(limit ? (q.length >= 3 ? { q } : { q: "?" }) : ({ q })).then((response: any) => {
        return callback(
            response.data.map((item: { name: string; id: number }) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            })
        );
    });
};
