import React, { useState } from 'react';
import { useProfile } from '../Components/Hooks/UserHooks';

const Navdata = () => {
    const { user } = useProfile();

    const [isCurrentState, setIsCurrentState] = useState('');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute('sub-items')) {
            const ul: any = document.getElementById('two-column-menu');
            const iconItems: any = ul.querySelectorAll('.nav-icon.active');
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove('active');
                var id = item.getAttribute('sub-items');
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove('show');
            });
        }
    }

    const menuItems: any = [
        {
            label: 'Config',
            isHeader: true,
        },
        {
            id: 'operators',
            label: 'Operators',
            icon: 'ri-focus-3-line',
            link: '/configs/operators',
            stateVariables: isCurrentState === 'Operators',
            click: function (e: any) {
                e.preventDefault();
                setIsCurrentState('Operators');
                updateIconSidebar(e);
            },
        },
        {
            id: 'games',
            label: 'Games',
            icon: 'ri-focus-3-line',
            link: '/configs/games',
            stateVariables: isCurrentState === 'Games',
            click: function (e: any) {
                e.preventDefault();
                setIsCurrentState('Games');
                updateIconSidebar(e);
            },
        },
    ];
    let otherMenuItems: any = [];
    if (user?.isAdmin) {
        otherMenuItems = [
            {
                label: 'Admin',
                isHeader: true,
            },
            {
                id: 'all-games',
                label: 'All Games',
                icon: 'ri-focus-3-line',
                link: '/games/all',
                stateVariables: isCurrentState === 'AllGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllGames');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'not-found-games',
                label: 'Not Found Games',
                icon: 'ri-focus-3-line',
                link: '/games/not-found',
                stateVariables: isCurrentState === 'NotFoundGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundGames');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-providers',
                label: 'All Provider',
                icon: 'ri-focus-3-line',
                link: '/providers/all',
                stateVariables: isCurrentState === 'AllProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllProviders');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'not-found-providers',
                label: 'Not Found Providers',
                icon: 'ri-focus-3-line',
                link: '/providers/not-found',
                stateVariables: isCurrentState === 'NotFoundProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundProviders');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-operators',
                label: 'All Operators',
                icon: 'ri-focus-3-line',
                link: '/operators/all',
                stateVariables: isCurrentState === 'AllOperators',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllOperators');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-jobs',
                label: 'All Jobs',
                icon: 'ri-focus-3-line',
                link: '/jobs/all',
                stateVariables: isCurrentState === 'AllJobs',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllJobs');
                    updateIconSidebar(e);
                },
            },
        ];
    } else {
        otherMenuItems = [
            {
                label: 'Reports',
                isHeader: true,
            },
            {
                id: 'reports-portfolio',
                label: 'Portfolio Overview',
                icon: 'ri-focus-3-line',
                link: '/reports/portfolio',
                stateVariables: isCurrentState === 'PortfolioOverview',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('PortfolioOverview');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'reports-games-on-operator',
                label: 'Games Positioning',
                icon: 'ri-focus-3-line',
                link: '/reports/games-on-operator',
                stateVariables: isCurrentState === 'GamesOnOperator',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('GamesOnOperator');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'reports-games-on-sections',
                label: 'Games On Sections',
                icon: 'ri-focus-3-line',
                link: '/reports/games-on-sections',
                stateVariables: isCurrentState === 'GamesOnSections',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('GamesOnSections');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'reports-position-updates',
                label: 'Position Updates',
                icon: 'ri-focus-3-line',
                link: '/reports/position-updates',
                stateVariables: isCurrentState === 'PositionUpdates',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('PositionUpdates');
                    updateIconSidebar(e);
                },
            },
        ];
    }

    for (const menuItem of otherMenuItems) {
        menuItems.push(menuItem);
    }
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
