import {
    fetchGamesOnOperatorSuccessAction,
    fetchGamesOnSectionsSuccessAction,
    fetchPortfolioSuccessAction,
    fetchPositionUpdatesSuccessAction,
    setReportsInProgressAction,
} from './reducer';
import { ReportApiService } from '../../Services/Api/ReportApiService';
import { setGlobalInProgressAction } from '../global/reducer';

export const fetchGamesOnOperator =
    (providerId: number, gameId: number, operatorId: number) => async (dispatch: any) => {
        dispatch(setReportsInProgressAction('fetchGamesOnOperator'));
        dispatch(setGlobalInProgressAction(true));
        try {
            const data = await ReportApiService.getGamesOnOperator(providerId, gameId, operatorId);

            if (data.code === 101) {
                dispatch(fetchGamesOnOperatorSuccessAction(data));
            }
        } catch (error) {}
        dispatch(setGlobalInProgressAction(false));
    };

export const fetchPositionUpdates =
    (page: number, pageSize: number, providerId: number, gameIds: number[] = [], operatorIds: number[] = [], date: string) =>
    async (dispatch: any) => {
        dispatch(setReportsInProgressAction('positionUpdates'));
        dispatch(setGlobalInProgressAction(true));
        try {
            const data = await ReportApiService.getPositionUpdates(page, pageSize, providerId, gameIds, operatorIds, date);

            if (data.code === 101) {
                dispatch(fetchPositionUpdatesSuccessAction(data));
            }
        } catch (error) {}
        dispatch(setGlobalInProgressAction(false));
    };

export const fetchPortfolio = (providerId: number, gameId: number, operatorId: number) => async (dispatch: any) => {
    dispatch(setReportsInProgressAction('fetchPortfolio'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await ReportApiService.getPortfolio(providerId, gameId, operatorId);

        if (data.code === 101) {
            dispatch(fetchPortfolioSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const fetchGamesOnSections = (gameId: number, operatorId: number) => async (dispatch: any) => {
    dispatch(setReportsInProgressAction('fetchGamesOnSections'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await ReportApiService.getGamesOnSections(gameId, operatorId);

        if (data.code === 101) {
            dispatch(fetchGamesOnSectionsSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
