import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadGameSuggestions } from '../../helpers/loadGameSuggestions';
import { loadSelectedOperatorSuggestions } from '../../helpers/loadSelectedOperatorSuggestions';
import { loadSelectedGameSuggestions } from '../../helpers/loadSelectedGameSuggestions';

type SelectSelectedGameProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    providerId?: number;
    isMulti?: boolean;
};

export const SelectSelectedGame = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Game...',
    providerId = 0,
    isMulti = false,
}: SelectSelectedGameProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadSelectedGameSuggestions(providerId)}
            placeholder={placeholder}
            hideImage={true}
            isMulti={isMulti}
        />
    );
};
