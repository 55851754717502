import { createSlice } from '@reduxjs/toolkit';
import { IApiCommonResponse, IJobsState } from '../types';
import { reRunJob } from './thunk';

export const initialState: IJobsState = {
    jobs: {
        data: [],
        pagination: {
            currentPage: 0,
            pageSize: 10,
            totalAmount: 0,
            totalPages: 0,
        },
    },
    isInProgress: {
        fetchAllJobs: false,
        reRunJob: false,
        deleteJob: false,
    },
    message: '',
    success: null,
};

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setJobsInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchAllJobsSuccessAction(state, action) {
            state.jobs.data = action.payload.data;
            state.jobs.pagination = action.payload.pagination;
            state.isInProgress.fetchAllJobs = false;
        },
        reRunJobSuccessAction(state) {
            state.isInProgress.reRunJob = false;
        },
        deleteJobSuccessAction(state, data: { payload: IApiCommonResponse }) {
            state.isInProgress.deleteJob = false;
            state.success = true;
            state.message = data.payload?.message ?? '';
        },
        resetJobsReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    reRunJobSuccessAction,
    deleteJobSuccessAction,
    setJobsInProgressAction,
    fetchAllJobsSuccessAction,
    resetJobsReducerStatus,
} = jobsSlice.actions;

export default jobsSlice.reducer;
