import { fetchSelectedGamesApi } from './backend_helper';

export const loadSelectedGameSuggestions = (provider_id?: number) => (q: string, callback: (response: any) => {}) => {
    fetchSelectedGamesApi({ q, provider_id }).then((response: any) => {
        return callback(
            response.data.map((provider: { name: string; image: string; id: number }) => {
                return {
                    image: provider.image,
                    label: provider.name,
                    value: provider.id,
                };
            })
        );
    });
};
