export type IOperator = {
    id: number;
    name: string;
    slug?: string;
    status: OperatorStatuses;
    last_succeed_job_id: number;
    visible_in?: string;
};

export enum VisibleInOptions {
    ALL = 'all',
    AM = 'am',
    BE = 'be',
    BG = 'BG',
    BR = 'br',
    AR = 'ar',
    FR = 'fr',
}

export enum OperatorStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}
