import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolio } from '../../../slices/reports/thunk';
import { portfolioDataSelector } from '../../../selectors/reportsSelectors';
import { AllGamesDialog } from './AllGamesDialog';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectOperator } from '../../../Components/Selects/SelectOperator';
import { SelectGame } from '../../../Components/Selects/SelectGame';
import { SelectProvider } from '../../../Components/Selects/SelectProvider';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { IReportGame } from '../../../Services/Api/IReportApiService';

export const Portfolio = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const { operators, isInProgress } = useSelector(portfolioDataSelector);

    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(-1);
    const [selectedProviderId, setSelectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        dispatch(fetchPortfolio(selectedProviderId, selectedGameId, selectedOperatorId));
    }, [selectedProviderId, selectedGameId, selectedOperatorId]);

    const smColumns = user.isAdmin ? 4 : 6;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb pageTitle={'Reports'} title={'Portfolio Overview'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                {user.isAdmin ? (
                                                    <Row>
                                                        <Col xs={12} sm={smColumns}>
                                                            <SelectProvider
                                                                setValue={setSelectedProviderId}
                                                                isInProgress={isInProgress}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={smColumns}>
                                                            <SelectGame
                                                                setValue={setSelectedGameId}
                                                                isInProgress={isInProgress}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={smColumns}>
                                                            <SelectOperator
                                                                setValue={setSelectedOperatorId}
                                                                isInProgress={isInProgress}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col xs={12} sm={smColumns}>
                                                            <SelectSelectedGame
                                                                setValue={setSelectedGameId}
                                                                isInProgress={isInProgress}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={smColumns}>
                                                            <SelectSelectedOperator
                                                                setValue={setSelectedOperatorId}
                                                                isInProgress={isInProgress}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                {!selectedOperatorId && !selectedProviderId && !selectedGameId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select a provider
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : operators.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-nowrap align-middle mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="min-w">#</th>
                                                                    <th>Operator</th>
                                                                    {!selectedGameId ? (
                                                                        <>
                                                                            <th className="min-w">Game Count</th>
                                                                            <th>Missing Games</th>
                                                                        </>
                                                                    ) : (
                                                                        <th>State</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {operators.map(
                                                                    ({ operator, games, missing }, index) => {
                                                                        return (
                                                                            <tr key={`section-info-${operator.id}`}>
                                                                                <td>{index + 1}</td>
                                                                                <td className="fs-5">
                                                                                    {operator.name}
                                                                                </td>
                                                                                {!selectedGameId ? (
                                                                                    <>
                                                                                        <td className="text-center">
                                                                                            <h4>
                                                                                                <Badge color="primary">
                                                                                                    {games.length}
                                                                                                </Badge>
                                                                                            </h4>
                                                                                        </td>
                                                                                        <td className="display-flex">
                                                                                            {missing
                                                                                                .slice(0, 4)
                                                                                                .map((game) => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            className="game-item"
                                                                                                            key={`section-info-${operator.id}-game-${game.id}`}
                                                                                                        >
                                                                                                            {game.name}
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                            {missing.length > 4 && (
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        setSelectedOperatorIndex(
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    color="primary"
                                                                                                >
                                                                                                    . . .
                                                                                                </Button>
                                                                                            )}
                                                                                        </td>
                                                                                    </>
                                                                                ) : (
                                                                                    <td>
                                                                                        {games.length ? (
                                                                                            <Badge color="success">
                                                                                                Exists
                                                                                            </Badge>
                                                                                        ) : (
                                                                                            <Badge color="warning">
                                                                                                Missing
                                                                                            </Badge>
                                                                                        )}
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AllGamesDialog
                isOpen={selectedOperatorIndex !== -1}
                onClose={() => {
                    setSelectedOperatorIndex(-1);
                }}
                itemKey={`operator-${operators?.[selectedOperatorIndex]?.operator.id}`}
                title={`Missing games on ${operators?.[selectedOperatorIndex]?.operator.name} (${operators?.[selectedOperatorIndex]?.missing?.length})`}
                games={operators?.[selectedOperatorIndex]?.missing}
            />
        </React.Fragment>
    );
};
