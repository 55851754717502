import {
    setSelectedOperatorsInProgressAction,
    fetchSelectedOperatorsSuccessAction,
    createSelectedOperatorSuccessAction,
    createSelectedOperatorFailedAction,
    deleteSelectedOperatorsSuccessAction,
    editSelectedOperatorsSuccessAction
} from './reducer';
import { ICreateSelectedOperatorApiRequestBody, IEditSelectedOperatorApiRequestBody, IFetchSelectedOperatorsApiRequestBody } from "slices/types";
import { setGlobalInProgressAction } from '../global/reducer';
import { fetchSelectedOperatorsApi } from "helpers/backend_helper";
import { SelectedOperatorApiService } from 'Services/Api/SelectedOperatorApiService';


export const fetchSelectedOperators = (params: IFetchSelectedOperatorsApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedOperatorsInProgressAction('fetchSelectedOperators'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await fetchSelectedOperatorsApi(params);

        if (data.code === 101) {
            dispatch(fetchSelectedOperatorsSuccessAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};

export const createSelectedOperator = (params: ICreateSelectedOperatorApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedOperatorsInProgressAction('createSelectedOperator'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedOperatorApiService.createSelectedOperator(params);

        if (data.code === 101) {
            dispatch(createSelectedOperatorSuccessAction(data));
        } else {
            dispatch(createSelectedOperatorFailedAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};

export const editSelectedOperator = (id: number, params: IEditSelectedOperatorApiRequestBody) => async (dispatch: any) => {
    dispatch(setSelectedOperatorsInProgressAction('editSelectedOperator'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedOperatorApiService.editSelectedOperator(id, params);

        if (data.code === 101) {
            dispatch(editSelectedOperatorsSuccessAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};

export const deleteSelectedOperator = (id: number) => async (dispatch: any) => {
    dispatch(setSelectedOperatorsInProgressAction('deleteSelectedOperator'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await SelectedOperatorApiService.deleteSelectedOperator(id);

        if (data.code === 101) {
            dispatch(deleteSelectedOperatorsSuccessAction(data));
        }
    } catch (error) { }
    dispatch(setGlobalInProgressAction(false));
};
