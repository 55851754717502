import { IOperator } from '../IOperatorApiService';
import { IApiPaginationResponse } from '../../../slices/types';

export type IJob = {
    original_id: number;
    created_at: string;
    status: string;
    info_url: string;
    games_parsed: number;
    operator: IOperator;
};

export type IFetchAllJobsApiResponse = {
    code: number;
    data: {
        data: IJob[];
        pagination: IApiPaginationResponse;
    };
};

export enum JobStatuses {
    ALL = 'all',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
    FAILED = 'failed',
}
