import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadSelectedOperatorSuggestions } from '../../helpers/loadSelectedOperatorSuggestions';

type SelectSelectedOperatorProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    isMulti?: boolean;
};

export const SelectSelectedOperator = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Operator...',
    isMulti = false,
}: SelectSelectedOperatorProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadSelectedOperatorSuggestions()}
            placeholder={placeholder}
            hideImage={true}
            isMulti={isMulti}
        />
    );
};
